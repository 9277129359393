/** @jsx h */
import { h } from 'preact'
import classNames from 'classnames'

import SiteContent from '../molecule/site-content'
import Footer from '../organism/footer'
import Header from '../organism/header'
import styles from '../../../css/04_template/horizontal-thirds.module.scss'

const HorizontalThirdsTemplate = ({ children, pageProps, footer, className }) => (
  <div className={classNames(className, styles['t-horizontal-thirds'])} >
    <Header
      className={styles['t-horizontal-thirds__header']}
      pageProps={pageProps}
    />

    <main className={styles['t-horizontal-thirds__content']}>
      <SiteContent>
        {children}
      </SiteContent>
    </main>

    <Footer className={styles['t-horizontal-thirds__footer']}>
      {footer}
    </Footer>
  </div>
)

export default HorizontalThirdsTemplate
