/** @jsx h */
import { h } from 'preact'
import classNames from 'classnames'

import SiteContent from '../molecule/site-content'
import Header from '../organism/header'
import styles from '../../../css/04_template/horizontal-halves.module.scss'

const HorizontalHalvesTemplate = ({ children, pageProps, className }) => (
  <div className={classNames(className, styles['t-horizontal-halves'])}>
    <Header
      className={styles['t-horizontal-halves__header']}
      pageProps={pageProps}
    />

    <SiteContent Element="main" className={styles['t-horizontal-halves__content']}>
      {children}
    </SiteContent>
  </div>
)

export default HorizontalHalvesTemplate
