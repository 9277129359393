/** @jsx h */
import { createContext, h } from 'preact'

const HeaderImageContext = createContext([])

export const Context = HeaderImageContext

export const HeaderImagesProvider = ({ images = [], children, ...rest } = {}) => (
  <HeaderImageContext.Provider value={images} {...rest}>
    {children}
  </HeaderImageContext.Provider>
)
