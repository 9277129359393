/** @jsx h */
import { h } from 'preact'

import Logo from '../../../assets/logo.svg'
import styles from '../../../css/01_atom/site-logo.module.scss'

const SiteLogo = ({ title }) => (
  <span className={styles['a-site-logo']}
        dangerouslySetInnerHTML={{
          __html: Logo.replace(/<svg/, `<svg role="img" alt="${title}" `),
        }} />
)

export default SiteLogo
