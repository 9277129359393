/** @jsx h */
import '../js/debug'
import { h } from 'preact'
import { useEffect, useMemo, useState } from 'preact/hooks'
import { CookieDisclaimer, LinkProvider, useMounted } from '@nonsensebb/components'
import { event, pageview } from 'react-ga/core'
import { useRouter } from 'next/router'
import Head from 'next/head'
import isBoolean from 'lodash-es/isBoolean'

import { init as initSentry } from '../js/utils/sentry'
import CFBeacon from '../js/components/atom/cf-beacon'
import { HeaderImagesProvider } from '../js/components/organism/header/header-image/provider'
import { SmartImgSettingsProvider } from '../js/components/atom/smart-img'
import ActiveSpinner from '../js/components/atom/active-spinner'
import HorizontalHalvesTemplate from '../js/components/template/horizontal-halves'
import HorizontalThirdsTemplate from '../js/components/template/horizontal-thirds'
import NextLinkWrapper from '../js/components/router/link'
import { BASE_STATIC_URL, BASE_WORDPRESS_URL, PATHS, TITLES } from '../js/config'
import { buildTitle } from '../js/meta'

import '../js/analytics'

import '../css/00_base/index.scss'

initSentry()

export function reportWebVitals({ id, name, label, value }) {
  event({
    category: `Next.js ${label} metric`,
    action: name,
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate.
  })
}

function BlogApp({ Component, pageProps, err }) {
  const router = useRouter()

  const isMounted = useMounted()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const startLoading = () => setLoading(true)
    const stopLoading = () => setLoading(false)

    router.events.on('routeChangeStart', startLoading)
    router.events.on('routeChangeComplete', stopLoading)
    router.events.on('routeChangeError', stopLoading)

    return () => {
      router.events.off('routeChangeStart', startLoading)
      router.events.off('routeChangeComplete', stopLoading)
      router.events.off('routeChangeError', stopLoading)
    }
  }, [router, setLoading])

  useEffect(
    () => {
      if (typeof window !== 'undefined') {
        pageview(router.asPath)
      }
    },
    [router.asPath],
  )

  // Prevent re-renders by locking this list down on first render
  const images = useMemo(() => process.env.NEXT_PUBLIC_HERO_IMAGES, [])
  const cookiePolicyLink = useMemo(() => PATHS.COOKIE_POLICY, [])

  const { Template, FooterComponent } = useMemo(
    () => {
      const FooterComponent = getFooterComponent({
        loading,
        Component,
      })

      const Template = FooterComponent !== false ? HorizontalThirdsTemplate : HorizontalHalvesTemplate

      return {
        Template,
        FooterComponent: isBoolean(FooterComponent) ? undefined : FooterComponent,
      }
    },
    [loading, Component],
  )

  return (
    <SmartImgSettingsProvider>
      <LinkProvider value={NextLinkWrapper}>
        <HeaderImagesProvider images={images}>
          <Head>
            <link rel="icon" sizes="196x196" href="/icon.png" />

            <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
            <link rel="apple-touch-icon" sizes="180x180"
                  href="/apple-touch-icon-iphone-6-plus.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-ipad-retina.png" />
            <link rel="apple-touch-icon" sizes="167x167" href="/apple-touch-icon-ipad-pro.png" />

            <meta name="theme-color" content="#282828" />

            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="anonymous" />
            <link rel="preconnect" href={BASE_STATIC_URL} crossOrigin="anonymous" />

            <link
              key="googleFontPreload"
              rel="preload"
              as="style"
              href="https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap"
            />

            <link
              key="googleFontJs"
              rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap"
              media={isMounted ? 'all' : 'print'}
            />

            <noscript key="GoogleFontNoScriptFallback">
              <link
                key="googleFontFallback"
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap"
                media="all"
              />
            </noscript>

            <title key="title">{buildTitle()}</title>

            <meta name="author" content="Luis Nabais" />

            <link
              key="rss"
              rel="alternate"
              type="application/rss+xml"
              title={buildTitle('RSS Feed')}
              href={`${BASE_WORDPRESS_URL}/feed`}
            />

            <meta property="og:site_name" content={TITLES.SITE} />

            {/* TODO: short link header - <link rel='shortlink' href='//bit.ly/14pzBur' />*/}
          </Head>

          <Template
            className="main-content"
            footer={FooterComponent && <FooterComponent {...pageProps} />}
            pageProps={pageProps}
          >
            {loading ? (
              <ActiveSpinner />
            ) : (
              <Component {...pageProps} err={err} />
            )}
          </Template>

          <CookieDisclaimer
            link={cookiePolicyLink}
            linkProps={{ internal: true }}
          />
        </HeaderImagesProvider>
      </LinkProvider>

      <CFBeacon token={process.env.NEXT_PUBLIC_CF_TOKEN} />
    </SmartImgSettingsProvider>
  )
}

function getFooterComponent({ loading, Component }) {
  const footerDisabled = Component.Footer === false
  const footerEnabled = Component.Footer === true
  const FooterComponent = Component.Footer

  if (footerDisabled) {
    return false
  }

  if (loading || footerEnabled || !FooterComponent) {
    return true
  }

  return FooterComponent
}

export default BlogApp
