/** @jsx h */
import { h } from 'preact'
import {
  HorizontalMenu,
  HorizontalMenuEntry,
  HorizontalMenuLink,
} from '@nonsensebb/components'

import { useRoute } from '../router/match'

function SiteMenu(props) {
  const { entries = [], ...rest } = props

  return (
    <HorizontalMenu {...rest}>
      {entries.map(
        (entry) => (
          <HorizontalMenuEntry key={entry.path}>
            <SiteMenuEntry {...entry} />
          </HorizontalMenuEntry>
        ),
      )}
    </HorizontalMenu>
  )
}

function SiteMenuEntry(props) {
  const { label, path, ...rest } = props

  const { matches } = useRoute(path)

  const linkProps = {
    ...(matches ? { 'aria-current': 'page' } : {}),
    active: matches,
    href: path,
    ...rest,
  }

  return (
    <HorizontalMenuLink {...linkProps}>
      {label}
    </HorizontalMenuLink>
  )
}

export default SiteMenu
