/** @jsx h */
import { h } from 'preact'
import { LinkWrapper } from '@nonsensebb/components'
import classNames from 'classnames'

import { PATHS, TITLES } from '../../../config'
import SiteLogo from '../../atom/site-logo'
import styles from '../../../../css/03_organism/site-header.module.scss'

import { HeaderNavigation } from './header-navigation'
import HeaderImage from './header-image'

function Header({ className, pageProps }) {
  return (
    <header className={classNames(className, styles['o-site-header'])}>
      <HeaderImage
        pageProps={pageProps}
        className={styles['o-site-header--image']}
      />

      <div className={styles['o-site-header__logo']}>
        <h1 className={styles['o-site-header__site-name']}>
          <LinkWrapper href={PATHS.HOME} title={TITLES.SITE} internal>
            <SiteLogo title={TITLES.SITE} />
          </LinkWrapper>
        </h1>
      </div>

      <HeaderNavigation />
    </header>
  )
}

export default Header
